import { Checkbox, Collapse, FormControlLabel, FormHelperText, TextField } from '@material-ui/core'

import AddressBlock from '#components/AddressBlock/AddressBlock'
import React from 'react'
import useLang from 'src/hooks/useLang'


const PickUp = props => {
  const { section, touched, errors, values, getFieldProps, getFieldValue } = props

  const lang = useLang(['order'])

  return (
    <div className="PickUp">
      <AddressBlock
        section={section}
        touched={touched ?? {}}
        errors={errors ?? {}}
        getFieldProps={getFieldProps}
      />

      <FormControlLabel
        className="my-15"
        label={lang.order.setEarliestPickUpTime}
        control={<Checkbox color="primary" checked={getFieldProps(`${section}.setEarliestPickUpTime`)['value']} />}
        {...getFieldProps(`${section}.setEarliestPickUpTime`)}
      />

      <Collapse in={values.setEarliestPickUpTime}>
        <TextField
          label={lang.order.dateTime}
          type="datetime-local"
          format=""
          {...getFieldProps(`${section}.earliestPickUpTime`)}
        />

        {errors.setEarliestPickUpTime && <FormHelperText error={true}>{errors.setEarliestPickUpTime}</FormHelperText>}
      </Collapse>
    </div>
  )
}

export default PickUp
