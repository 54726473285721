import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core'

import React, { useState } from 'react'
import useLang from 'src/hooks/useLang'

const OrderInfo = props => {
  const { services, selectedSize, section, touched, errors, getFieldProps, handleChange, getFieldValue, values } = props

  const lang = useLang(['order'])

  const [selectedService, setSelectedService] = useState(values?.service ? services.find(service => service.service_id === values.service) : null)

  const onChangeServiceSelect = event => {
    handleChange(event)
    setSelectedService(services.find(service => service.service_id === event.target.value));
  }

  return (
    <div className="OrderInfo">
      <FormControl fullWidth className="mb-20">
        <InputLabel required>{lang.order.service}</InputLabel>
        <Select
          error={touched.service && !!errors.service}
          MenuProps={{
            onExiting: () => document.activeElement.blur(),
          }}
          {...getFieldProps(section ? `${section}.service` : 'service')}
          onChange={onChangeServiceSelect}
        >
          {services ? services.map(service => (
            <MenuItem key={`service-${service.service_id}`} value={service.service_id} selected={service.default ? true : false}>
              {service.name}
            </MenuItem>
          )) : ''}
        </Select>
        <FormHelperText error={true}>{touched.service && errors.service}</FormHelperText>
      </FormControl>

      {selectedService?.service_id ? (
        <FormControl fullWidth>
          <InputLabel required>{lang.order.size}</InputLabel>
          <Select
            MenuProps={{
              onExiting: () => document.activeElement.blur(),
            }}
            {...getFieldProps(section ? `${section}.size` : 'size')}
          >
            {selectedService.sizes.map(size => (
              <MenuItem key={`size-${size.size_id}`} value={size.size_id} selected={size.size_id === selectedSize?.size_id}>
                {size.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>) : null}
    </div>
  )
}

export default OrderInfo
