import { InputAdornment, TextField } from '@material-ui/core'
import React, { useContext } from 'react'

import AddressBlock from '#components/AddressBlock/AddressBlock'
import SettingsContext from '#context/settings'
import useLang from 'src/hooks/useLang'

const Handover = props => {
  const { section, touched, errors, getFieldProps, setFieldValue, getFieldValue } = props

  const { settings } = useContext(SettingsContext)

  const lang = useLang(['order'])

  const handleChangeCod = event => {
    const value = event.target.value

    if (isNaN(value)) {
      return false
    }

    setFieldValue('handover.cod', value)
  }

  const getCurrency = () => {
    switch (settings.currency) {
      case 'usd':
        return '$'

      case 'eur':
        return '€'

      case 'huf':
        return 'Ft'

      case 'ron':
        return 'lei'

      default:
        return '$'
    }
  }

  return (
    <div className="Handover">
      <AddressBlock
        section={section}
        touched={touched ?? {}}
        errors={errors ?? {}}
        getFieldProps={getFieldProps}
      />

      <TextField
        className="mt-20"
        fullWidth
        label={lang.order.cod}
        InputProps={{
          endAdornment: <InputAdornment position="end">{getCurrency()}</InputAdornment>,
        }}
        error={touched.cod && !!errors.cod}
        helperText={touched.cod && errors.cod}
        {...getFieldProps(section ? `${section}.cod` : 'cod')}
        onChange={handleChangeCod}
      />
    </div>
  )
}

export default Handover
